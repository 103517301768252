"use client";

import * as React from "react";

import { cn } from "@/lib/utils";

function IconNextChat({
  className,
  inverted,
  ...props
}: React.ComponentProps<"svg"> & { inverted?: boolean }) {
  const id = React.useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn("size-4", className)}
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
      <path d="M9.5 9h.01" />
      <path d="M14.5 9h.01" />
      <path d="M9.5 13a3.5 3.5 0 0 0 5 0" />
    </svg>
  );
}

function IconOpenAI({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    // <svg
    //   fill="currentColor"
    //   viewBox="0 0 24 24"
    //   role="img"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={cn("size-4", className)}
    //   {...props}
    // >
    //   <title>OpenAI icon</title>
    //   <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn("size-4", className)}
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
      <path d="M9.5 9h.01" />
      <path d="M14.5 9h.01" />
      <path d="M9.5 13a3.5 3.5 0 0 0 5 0" />
    </svg>
  );
}

function IconTung({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      height="500"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="1ea4ab8990">
          <path
            d="M 11.425781 197 L 363 197 L 363 366.261719 L 11.425781 366.261719 Z M 11.425781 197 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="bde384df5c">
          <path
            d="M 363 216 L 363.925781 216 L 363.925781 217 L 363 217 Z M 363 216 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="2f2762a2a7">
          <path
            d="M 348 181 L 363.925781 181 L 363.925781 197 L 348 197 Z M 348 181 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="a735767946">
          <path
            d="M 156 8.511719 L 173 8.511719 L 173 25 L 156 25 Z M 156 8.511719 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <rect
        x="-37.5"
        width="450"
        fill="#ffffff"
        y="-37.499999"
        height="449.999989"
        fill-opacity="1"
      />
      <rect
        x="-37.5"
        width="450"
        fill="#001c30"
        y="-37.499999"
        height="449.999989"
        fill-opacity="1"
      />
      <path
        fill="#0a4570"
        d="M 95.988281 203.652344 C 99.15625 226.519531 106.445312 249.050781 119.148438 268.457031 C 120.160156 270.785156 124.4375 274.417969 123.898438 276.597656 C 121.320312 283.117188 113.761719 284.988281 108.015625 287.753906 C 78.179688 302.722656 88.039062 329.386719 120.492188 322.851562 C 127.164062 321.527344 128.839844 325.726562 135.167969 323.222656 C 140.097656 321.277344 146.664062 332.863281 151.589844 330.910156 C 167.648438 330.09375 182.308594 344.183594 195.726562 351.167969 C 196.277344 346.171875 194.5625 333.484375 214.867188 328.804688 C 223.859375 326.734375 229.230469 310.457031 243.691406 308.546875 C 249.085938 279.144531 199.476562 238.507812 169.226562 223 C 129.210938 202.488281 167.257812 94.808594 101.5 85.585938 C 82.804688 92.863281 83.527344 116.175781 87.679688 132.621094 C 92.761719 158.625 90.910156 177.644531 95.992188 203.652344 Z M 95.988281 203.652344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 152.210938 297.234375 C 145.410156 305.203125 114.410156 323.828125 89.195312 312.019531 C 87.710938 308.765625 87.542969 305 88.640625 301.484375 C 93.640625 283.09375 76.097656 284.347656 77.492188 269.4375 C 77.578125 268.398438 77.195312 267.367188 76.453125 266.636719 C 67.207031 257.917969 73.550781 255.195312 73.457031 246.367188 C 71.945312 233.675781 57.613281 234.75 50.003906 226.578125 C 39.707031 211.835938 78.957031 196.308594 70.960938 155.3125 C 67.730469 134.820312 74.230469 113.957031 88.136719 98.605469 C 165.308594 143.550781 91.28125 181.214844 100.4375 214.175781 C 110.316406 249.730469 202.492188 238.378906 152.210938 297.234375 L 152.207031 297.234375 Z M 152.210938 297.234375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#2fc0f2"
        d="M 210.773438 317.058594 L 147.074219 328.804688 L 130.066406 320.839844 C 111.449219 329.570312 84.320312 322.851562 88.640625 301.488281 C 93.652344 283.117188 76.09375 284.335938 77.492188 269.441406 C 76.496094 263.550781 68.328125 262.398438 72.414062 252.722656 C 78.730469 236.480469 58.796875 234.984375 50.003906 226.582031 C 39.535156 212.179688 78.902344 196.40625 70.960938 155.3125 C 67.726562 134.8125 74.230469 113.957031 88.140625 98.605469 L 89.875 99.625 C 103.058594 96.5625 131.632812 116.375 128.378906 162.511719 C 125.355469 226.691406 136.355469 228.816406 171.167969 244.347656 C 196.1875 255.511719 216.167969 284.964844 210.773438 317.050781 Z M 210.773438 317.058594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 191.550781 319.726562 C 190.929688 335.597656 187.273438 348.960938 188.164062 351.164062 C 188.523438 352.046875 188.792969 352.847656 188.992188 353.566406 C 185.1875 354.855469 181.40625 356.039062 177.644531 357.121094 C 170.777344 354.191406 163.441406 349.359375 163.441406 349.074219 C 154.960938 335.042969 152.925781 331.8125 147.191406 328.609375 C 143.738281 326.675781 138.949219 324.761719 130.59375 320.5 C 130.59375 320.5 177.816406 306.375 186.230469 286.582031 C 190.996094 296.980469 191.976562 308.933594 191.550781 319.726562 Z M 191.550781 319.726562 "
        fill-opacity="0.3"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 152.210938 297.234375 C 145.289062 306.878906 108.667969 323.367188 89.195312 312.019531 C 87.710938 308.765625 87.542969 305 88.640625 301.484375 C 93.667969 283.195312 76.097656 284.296875 77.492188 269.4375 C 77.578125 268.398438 77.195312 267.367188 76.453125 266.636719 C 67.203125 257.90625 73.550781 255.203125 73.457031 246.367188 C 71.945312 233.675781 57.613281 234.75 50.003906 226.578125 C 39.695312 211.875 78.960938 196.28125 70.960938 155.3125 C 67.714844 134.785156 74.289062 113.871094 88.136719 98.605469 C 165.308594 143.550781 91.28125 181.214844 100.4375 214.175781 C 110.316406 249.730469 202.492188 238.378906 152.210938 297.234375 Z M 152.210938 297.234375 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 124.980469 304.113281 C 114.835938 314.574219 104.035156 319.507812 89.195312 312.023438 C 87.710938 308.773438 87.542969 305.003906 88.640625 301.488281 C 93.644531 283.097656 76.097656 284.351562 77.492188 269.441406 C 77.578125 268.402344 77.195312 267.371094 76.453125 266.640625 C 67.164062 257.8125 73.574219 255.25 73.457031 246.371094 C 72.183594 233.914062 57.085938 234.402344 50.003906 226.582031 C 46.441406 221.695312 48.644531 216.640625 52.789062 210.480469 C 89.070312 165.515625 58.519531 157.730469 76.558594 116.058594 C 126.839844 156.378906 64.78125 190.710938 73.210938 221.046875 C 83.085938 256.601562 175.273438 245.257812 124.980469 304.109375 Z M 124.980469 304.113281 "
        fill-opacity="0.3"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 61.757812 233.710938 C 50.082031 229.082031 43.261719 223.277344 52.792969 210.476562 C 51.035156 219.339844 54.765625 228.035156 61.757812 233.710938 Z M 61.757812 233.710938 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 70.960938 155.3125 C 69.746094 147.589844 69.914062 139.804688 71.339844 132.273438 C 79.019531 145.207031 76.941406 157.511719 71.941406 169.25 C 72.199219 164.949219 71.941406 160.320312 70.964844 155.316406 Z M 70.960938 155.3125 "
        fill-opacity="0.39"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 104.964844 304.113281 C 100.726562 308.109375 96.878906 311.441406 92.691406 313.488281 C 91.570312 313.066406 90.40625 312.578125 89.199219 312.023438 C 87.714844 308.773438 87.546875 305.003906 88.644531 301.488281 C 93.652344 283.097656 76.101562 284.351562 77.496094 269.441406 C 77.582031 268.402344 77.203125 267.371094 76.457031 266.640625 C 64.789062 256.792969 78.683594 249.699219 70.929688 239.902344 C 98.585938 255.160156 143.40625 259.117188 104.964844 304.117188 Z M 104.964844 304.113281 "
        fill-opacity="0.05"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 321.015625 169.488281 C 320.972656 171.8125 320.875 174.125 320.710938 176.453125 C 318.214844 207.785156 306.449219 234.792969 288.960938 262.191406 C 284.742188 270.363281 276.703125 274.882812 276.992188 289.195312 L 273.148438 289.894531 C 272.53125 272.914062 281.898438 268.0625 287.035156 257.933594 C 298.605469 239.445312 306.714844 222.347656 312.160156 202.628906 C 312.589844 201.039062 312.988281 199.457031 313.363281 197.882812 C 316.550781 184.339844 317.835938 171.101562 316.71875 157.449219 C 310.828125 77.074219 215.636719 34.195312 144.933594 61.054688 C 108.367188 75.882812 66.058594 109.679688 72.71875 153.835938 C 77.980469 186.984375 60.972656 200.566406 50.246094 218.660156 C 46.886719 232.65625 74.894531 229.6875 75.398438 246.140625 C 76.039062 254.5625 69.144531 257.394531 77.824219 265.246094 C 81.847656 270.375 76.328125 275.222656 84.832031 281.550781 C 97.234375 292.484375 87.183594 301.859375 90.976562 311.210938 C 104.375 332.875 122.6875 315.015625 140.621094 323.621094 C 140.621094 323.621094 155.265625 330.472656 155.265625 330.472656 L 153.613281 334.007812 C 153.613281 334.007812 138.960938 327.15625 138.960938 327.15625 C 133.707031 324.695312 127.683594 324.082031 122.003906 325.421875 C 107.039062 328.996094 94.214844 324.835938 87.421875 312.824219 C 82.832031 303.40625 92.65625 293.109375 82.488281 284.675781 C 78.148438 281.4375 75.800781 277.210938 75.507812 272.105469 C 75.226562 270.828125 76.042969 269.109375 75.085938 268.03125 C 71.097656 264.089844 68.769531 260.519531 69.558594 255.324219 C 70.070312 252.5625 72.082031 249.484375 71.519531 246.597656 C 70.320312 232.199219 40.6875 234.988281 46.65625 217.109375 C 47.972656 214.066406 50.421875 210.773438 53.261719 206.957031 C 86.671875 170.449219 50.601562 136.21875 86.6875 97.292969 C 114.503906 67.167969 147.40625 49.972656 187.210938 49.082031 C 255.617188 46.71875 323.382812 97.453125 321.011719 169.480469 Z M 321.015625 169.488281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 245.769531 268.839844 C 245.769531 268.839844 218.523438 247.726562 218.523438 247.726562 C 218.511719 247.726562 186.5625 199.113281 186.53125 199.082031 C 180.140625 182.304688 168.503906 147.988281 163.59375 130.773438 L 162.140625 130.582031 L 163.109375 129.882812 L 139.046875 95.855469 L 117.070312 73.625 L 118.363281 72.722656 L 140.25 94.851562 C 140.292969 94.90625 164.328125 128.910156 164.371094 128.96875 L 204.902344 99.785156 L 209.527344 135.753906 L 217.152344 161.828125 C 228.269531 146.328125 249.472656 113.910156 259.464844 97.570312 L 288.460938 134.503906 L 269.109375 153.722656 L 247.8125 183.015625 L 276.03125 211.738281 C 276.03125 211.738281 245.777344 268.839844 245.769531 268.839844 Z M 219.6875 246.65625 L 245.257812 266.472656 L 274.113281 212.023438 L 245.769531 183.175781 L 267.960938 152.667969 L 286.375 134.382812 L 259.605469 100.28125 C 249.5 116.90625 227.882812 149.566406 216.574219 165.429688 L 208.148438 136.625 L 165.296875 131 C 170.136719 147.976562 181.628906 181.824219 187.941406 198.378906 C 187.941406 198.378906 219.691406 246.652344 219.691406 246.652344 Z M 166.242188 129.550781 L 207.851562 135.015625 L 203.6875 102.585938 Z M 166.242188 129.550781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 217.902344 164.40625 L 172.382812 164.40625 L 209.171875 134.566406 L 217.898438 164.40625 Z M 176.789062 162.847656 L 215.816406 162.847656 L 208.332031 137.265625 Z M 176.789062 162.847656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 227.464844 213.054688 L 185.894531 199.105469 L 217.308594 161.886719 L 227.460938 213.054688 Z M 188.570312 198.355469 L 225.410156 210.714844 L 216.410156 165.375 L 188.574219 198.359375 Z M 188.570312 198.355469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 246.839844 183.878906 L 246.730469 182.320312 L 294.417969 179.035156 L 267.964844 153.785156 L 269.042969 152.65625 L 298.058594 180.347656 Z M 246.839844 183.878906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 163.425781 129.957031 L 171.800781 75.578125 L 173.34375 75.8125 L 164.96875 130.191406 Z M 163.425781 129.957031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.546875 277.929688 L 244.917969 268.328125 L 226.621094 214.832031 L 219.871094 247.339844 L 218.339844 247.023438 L 225.882812 210.707031 L 221.863281 210.550781 L 245.609375 183.257812 L 214.832031 163.234375 L 266.558594 152.808594 L 238.203125 133.109375 L 208.847656 136.679688 L 208.660156 135.132812 L 238.605469 131.480469 L 270.457031 153.609375 L 218.882812 164 L 247.964844 182.917969 L 225.175781 209.109375 L 226.203125 209.148438 L 226.253906 208.910156 L 226.335938 209.152344 L 275.691406 211.109375 L 291.546875 277.910156 Z M 246.109375 266.976562 L 289.457031 275.902344 L 274.441406 212.632812 L 226.871094 210.75 Z M 246.109375 266.976562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 328.371094 220.597656 L 273.746094 212.476562 L 295.414062 179.527344 L 286.765625 135.199219 L 238.097656 133.070312 C 222.566406 118.230469 189.839844 89.65625 172.761719 76.839844 L 172.613281 77.074219 L 172.359375 76.71875 L 138.515625 96.941406 L 149.964844 43.054688 L 172.53125 74.292969 L 195.628906 38.082031 L 227.285156 73.878906 L 205.367188 101.09375 L 238.734375 131.53125 L 288.066406 133.683594 L 297.074219 179.839844 L 276.398438 211.28125 L 328.609375 219.039062 C 328.609375 219.039062 328.386719 220.582031 328.375 220.589844 Z M 173.59375 75.519531 L 203.242188 99.34375 L 195.234375 41.59375 Z M 196.851562 41.839844 L 204.8125 99.285156 L 225.226562 73.9375 L 196.84375 41.839844 Z M 150.75 46.828125 L 140.777344 93.769531 L 171.429688 75.453125 L 150.75 46.824219 Z M 150.75 46.828125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 312.148438 107.0625 L 311.363281 106.03125 L 313.894531 103.324219 C 314.558594 103.945312 316.074219 105.363281 316.753906 105.996094 C 316.460938 106.304688 313.695312 109.265625 313.328125 109.652344 Z M 312.148438 107.0625 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 332.722656 172.363281 C 332.753906 172.261719 332.613281 171.429688 332.601562 171.3125 L 334.992188 173.402344 L 332.683594 173.894531 Z M 332.722656 172.363281 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 327.878906 220.3125 L 294.835938 179.191406 L 329.082031 171.867188 L 286.289062 134.503906 L 313.949219 104.984375 L 315.089844 106.046875 L 288.535156 134.394531 L 332.453125 172.742188 L 297.640625 180.191406 L 329.09375 219.339844 Z M 327.878906 220.3125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 287.453125 135.226562 L 287.367188 133.671875 L 325.335938 131.570312 L 325.421875 133.125 Z M 287.453125 135.226562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 225.785156 74.535156 L 226.722656 73.289062 L 260 98.304688 L 259.058594 99.550781 Z M 225.785156 74.535156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 204.25 100.402344 L 258.609375 97.480469 L 258.695312 99.035156 L 204.335938 101.957031 Z M 204.25 100.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 261.484375 54.941406 L 261.484375 60.890625 L 257.96875 58.421875 L 257.574219 58.640625 L 257.574219 54.941406 Z M 261.484375 54.941406 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 258.75 56.117188 L 260.308594 56.117188 L 260.308594 98.929688 L 258.75 98.929688 Z M 258.75 56.117188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 296.703125 82.328125 C 296.703125 82.328125 293.976562 83.699219 293.976562 83.707031 L 292.578125 82.726562 L 292.972656 79.832031 C 293.402344 79.617188 294.527344 79.046875 294.933594 78.84375 C 295.449219 79.859375 296.183594 81.304688 296.703125 82.328125 Z M 296.703125 82.328125 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.171875 98.242188 L 294.417969 80.429688 L 295.121094 81.820312 L 259.878906 99.632812 Z M 259.171875 98.242188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 296.703125 82.328125 C 296.703125 82.328125 293.976562 83.699219 293.976562 83.707031 L 292.578125 82.726562 C 292.714844 81.757812 293.046875 79.308594 293.179688 78.371094 L 294.308594 79.164062 L 294.9375 78.847656 L 295.539062 80.035156 L 296.226562 80.515625 L 295.964844 80.878906 C 295.964844 80.878906 296.703125 82.332031 296.703125 82.332031 Z M 296.703125 82.328125 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 316.214844 106.558594 L 313.320312 109.648438 C 312.964844 108.828125 312.015625 106.625 311.355469 106.027344 L 313.890625 103.320312 C 314.304688 103.6875 315.246094 104.570312 315.523438 105.042969 C 315.523438 105.042969 316.214844 106.558594 316.214844 106.558594 Z M 316.214844 106.558594 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.144531 277.363281 L 289.855469 276.476562 L 328.78125 220.148438 L 329.980469 172.351562 L 323.988281 130.285156 L 313.15625 106.453125 L 295.28125 83.078125 L 288.179688 134.550781 L 286.628906 134.339844 L 294.238281 79.148438 C 294.28125 79.226562 314.539062 105.628906 314.539062 105.726562 C 314.539062 105.726562 325.5 129.84375 325.5 129.84375 C 325.53125 129.910156 331.511719 172.171875 331.542969 172.257812 C 331.539062 172.316406 330.328125 220.582031 330.324219 220.648438 C 330.253906 220.742188 291.230469 277.230469 291.136719 277.359375 Z M 291.144531 277.363281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 261.484375 56.113281 L 261.484375 60.890625 L 257.96875 58.421875 L 257.574219 58.640625 L 257.574219 54.941406 L 259.816406 54.941406 Z M 261.484375 56.113281 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 296.222656 80.511719 L 295.960938 80.875 C 295.960938 80.875 293.976562 83.707031 293.96875 83.707031 L 292.574219 82.726562 C 292.710938 81.757812 293.042969 79.308594 293.175781 78.371094 C 294.066406 78.996094 295.34375 79.890625 296.226562 80.511719 Z M 296.222656 80.511719 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 293.683594 82.074219 L 258.035156 57.035156 L 226.636719 74.59375 L 225.871094 73.230469 L 258.128906 55.195312 L 294.585938 80.792969 Z M 293.683594 82.074219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 117.425781 73.984375 L 106.578125 65.949219 C 106.578125 65.949219 150.121094 44.1875 150.121094 44.1875 L 195.777344 38.59375 C 196.015625 38.59375 258.066406 55.351562 258.285156 55.367188 L 257.882812 56.875 L 195.664062 40.183594 L 150.578125 45.710938 L 109.550781 66.210938 L 118.359375 72.734375 L 117.425781 73.988281 Z M 117.425781 73.984375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0a4570"
        d="M 119.347656 322.003906 C 141.289062 315.703125 164.484375 308.65625 181.839844 293.199219 C 165.183594 309.765625 142.199219 318.160156 120.394531 325.761719 L 119.34375 322.003906 Z M 119.347656 322.003906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#1ea4ab8990)">
        <path
          fill="#2fc0f2"
          d="M 362.78125 218.332031 C 345.210938 242.984375 325.515625 264.976562 305.363281 283.253906 C 267.3125 317.722656 227.074219 340.695312 188.992188 353.566406 C 185.1875 354.855469 181.40625 356.039062 177.644531 357.121094 C 56.101562 392.25 -38.441406 323.378906 40.410156 197.894531 C -18.582031 308.6875 54.003906 341.0625 147.191406 328.609375 C 161.625 326.679688 176.546875 323.675781 191.550781 319.726562 C 259.914062 301.71875 329.980469 263.988281 362.78125 218.332031 Z M 362.78125 218.332031 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#bde384df5c)">
        <path
          fill="#0a4570"
          d="M 363.863281 216.816406 C 363.863281 216.816406 363.855469 216.828125 363.847656 216.832031 C 363.855469 216.828125 363.855469 216.820312 363.859375 216.816406 Z M 363.863281 216.816406 "
          fill-opacity="0.3"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#fe2860"
        d="M 164.183594 120.804688 C 176.382812 121.011719 176.375 139.125 164.183594 139.332031 C 151.988281 139.125 151.992188 121.011719 164.183594 120.804688 Z M 164.183594 120.804688 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 164.183594 136.984375 C 155.117188 136.9375 155.117188 123.1875 164.183594 123.136719 C 173.25 123.1875 173.25 136.9375 164.183594 136.984375 Z M 164.183594 136.984375 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 164.183594 134.648438 C 158.230469 134.730469 158.238281 125.40625 164.183594 125.488281 C 170.132812 125.410156 170.132812 134.734375 164.183594 134.648438 Z M 164.183594 134.648438 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 164.183594 132.304688 C 161.273438 132.347656 161.273438 127.789062 164.183594 127.828125 C 167.097656 127.789062 167.097656 132.347656 164.183594 132.304688 Z M 164.183594 132.304688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 208.75 131.335938 C 214.703125 131.25 214.703125 140.582031 208.75 140.492188 C 202.796875 140.578125 202.796875 131.246094 208.75 131.335938 Z M 208.75 131.335938 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 208.75 139.339844 C 204.300781 139.40625 204.300781 132.429688 208.75 132.492188 C 213.199219 132.429688 213.199219 139.40625 208.75 139.339844 Z M 208.75 139.339844 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 208.75 138.183594 C 205.808594 138.222656 205.808594 133.609375 208.75 133.648438 C 211.691406 133.609375 211.691406 138.222656 208.75 138.183594 Z M 208.75 138.183594 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 208.75 137.027344 C 207.308594 137.042969 207.308594 134.792969 208.75 134.808594 C 210.191406 134.792969 210.191406 137.042969 208.75 137.027344 Z M 208.75 137.027344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 216.855469 158.824219 C 223.542969 158.855469 223.542969 169.003906 216.855469 169.035156 C 210.167969 169.003906 210.167969 158.855469 216.855469 158.824219 Z M 216.855469 158.824219 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 216.855469 167.746094 C 211.898438 167.8125 211.898438 160.046875 216.855469 160.113281 C 221.8125 160.046875 221.8125 167.8125 216.855469 167.746094 Z M 216.855469 167.746094 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 216.855469 166.453125 C 213.578125 166.5 213.578125 161.359375 216.855469 161.40625 C 220.136719 161.363281 220.136719 166.503906 216.855469 166.453125 Z M 216.855469 166.453125 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 216.855469 165.164062 C 215.25 165.1875 215.25 162.675781 216.855469 162.695312 C 218.457031 162.675781 218.457031 165.1875 216.855469 165.164062 Z M 216.855469 165.164062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 173.460938 159.394531 C 179.347656 159.3125 179.351562 168.542969 173.460938 168.457031 C 167.574219 168.542969 167.574219 159.3125 173.460938 159.394531 Z M 173.460938 159.394531 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 173.460938 167.3125 C 169.058594 167.371094 169.058594 160.484375 173.460938 160.542969 C 177.863281 160.480469 177.863281 167.378906 173.460938 167.3125 Z M 173.460938 167.3125 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 173.460938 166.167969 C 170.550781 166.207031 170.550781 161.648438 173.460938 161.6875 C 176.371094 161.648438 176.371094 166.207031 173.460938 166.167969 Z M 173.460938 166.167969 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 173.460938 165.027344 C 172.035156 165.042969 172.035156 162.8125 173.460938 162.832031 C 174.886719 162.8125 174.886719 165.042969 173.460938 165.027344 Z M 173.460938 165.027344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 268.507812 147.617188 C 275.847656 147.648438 275.847656 158.792969 268.507812 158.824219 C 261.171875 158.792969 261.171875 147.648438 268.507812 147.617188 Z M 268.507812 147.617188 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 268.507812 157.40625 C 263.066406 157.484375 263.066406 148.957031 268.507812 149.03125 C 273.949219 148.957031 273.949219 157.484375 268.507812 157.40625 Z M 268.507812 157.40625 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 268.507812 155.992188 C 264.90625 156.042969 264.90625 150.398438 268.507812 150.453125 C 272.105469 150.402344 272.113281 156.046875 268.507812 155.992188 Z M 268.507812 155.992188 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 268.507812 154.578125 C 266.75 154.605469 266.75 151.839844 268.507812 151.867188 C 270.269531 151.839844 270.269531 154.605469 268.507812 154.578125 Z M 268.507812 154.578125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 287.414062 124.4375 C 300.589844 124.660156 300.589844 144.238281 287.414062 144.460938 C 274.234375 144.238281 274.234375 124.660156 287.414062 124.4375 Z M 287.414062 124.4375 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 287.414062 141.925781 C 277.566406 141.75 277.566406 127.136719 287.414062 126.964844 C 297.257812 127.136719 297.257812 141.75 287.414062 141.925781 Z M 287.414062 141.925781 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 287.414062 139.398438 C 280.929688 139.367188 280.929688 129.535156 287.414062 129.503906 C 293.894531 129.535156 293.894531 139.367188 287.414062 139.398438 Z M 287.414062 139.398438 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 287.414062 136.871094 C 284.269531 136.914062 284.261719 131.980469 287.414062 132.023438 C 290.5625 131.980469 290.5625 136.914062 287.414062 136.871094 Z M 287.414062 136.871094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.328125 167.574219 C 337.808594 167.601562 337.808594 177.445312 331.328125 177.472656 C 324.847656 177.445312 324.847656 167.601562 331.328125 167.574219 Z M 331.328125 167.574219 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.328125 176.226562 C 326.519531 176.292969 326.519531 168.757812 331.328125 168.824219 C 336.136719 168.757812 336.136719 176.292969 331.328125 176.226562 Z M 331.328125 176.226562 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.328125 174.972656 C 328.148438 175.015625 328.148438 170.03125 331.328125 170.078125 C 334.507812 170.03125 334.507812 175.015625 331.328125 174.972656 Z M 331.328125 174.972656 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 331.328125 173.722656 C 329.769531 173.746094 329.777344 171.304688 331.328125 171.328125 C 332.878906 171.304688 332.882812 173.746094 331.328125 173.722656 Z M 331.328125 173.722656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 246.785156 175.617188 C 256.636719 175.78125 256.632812 190.410156 246.785156 190.578125 C 236.9375 190.414062 236.9375 175.789062 246.785156 175.617188 Z M 246.785156 175.617188 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 246.785156 188.691406 C 239.464844 188.648438 239.464844 177.546875 246.785156 177.511719 C 254.105469 177.550781 254.101562 188.652344 246.785156 188.691406 Z M 246.785156 188.691406 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 246.785156 186.800781 C 241.976562 186.867188 241.976562 179.332031 246.785156 179.398438 C 251.59375 179.332031 251.59375 186.867188 246.785156 186.800781 Z M 246.785156 186.800781 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 246.785156 184.910156 C 244.433594 184.941406 244.433594 181.261719 246.785156 181.292969 C 249.136719 181.261719 249.136719 184.941406 246.785156 184.910156 Z M 246.785156 184.910156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 329.554688 214.570312 C 337.1875 214.605469 337.1875 226.191406 329.554688 226.222656 C 321.925781 226.1875 321.925781 214.601562 329.554688 214.570312 Z M 329.554688 214.570312 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 329.554688 224.75 C 323.898438 224.832031 323.898438 215.964844 329.554688 216.039062 C 335.210938 215.960938 335.210938 224.828125 329.554688 224.75 Z M 329.554688 224.75 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 329.554688 223.28125 C 325.808594 223.335938 325.808594 217.464844 329.554688 217.519531 C 333.300781 217.464844 333.300781 223.335938 329.554688 223.28125 Z M 329.554688 223.28125 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 329.554688 221.808594 C 327.726562 221.835938 327.726562 218.964844 329.554688 218.988281 C 331.386719 218.960938 331.386719 221.832031 329.554688 221.808594 Z M 329.554688 221.808594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 204.539062 93.199219 C 214.390625 93.371094 214.390625 108 204.539062 108.167969 C 194.6875 107.996094 194.6875 93.367188 204.539062 93.199219 Z M 204.539062 93.199219 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 204.539062 106.277344 C 197.214844 106.238281 197.21875 95.132812 204.539062 95.09375 C 211.859375 95.132812 211.859375 106.238281 204.539062 106.277344 Z M 204.539062 106.277344 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 204.539062 104.386719 C 199.730469 104.453125 199.730469 96.917969 204.539062 96.984375 C 209.347656 96.917969 209.347656 104.453125 204.539062 104.386719 Z M 204.539062 104.386719 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 204.539062 102.492188 C 202.1875 102.523438 202.1875 98.84375 204.539062 98.875 C 206.890625 98.84375 206.890625 102.523438 204.539062 102.492188 Z M 204.539062 102.492188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 225.351562 22.949219 C 235.203125 23.121094 235.203125 37.75 225.351562 37.917969 C 215.5 37.746094 215.5 23.117188 225.351562 22.949219 Z M 225.351562 22.949219 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 225.351562 36.027344 C 218.027344 35.988281 218.03125 24.886719 225.351562 24.84375 C 232.671875 24.886719 232.671875 35.988281 225.351562 36.027344 Z M 225.351562 36.027344 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 225.351562 34.136719 C 220.542969 34.203125 220.542969 26.667969 225.351562 26.734375 C 230.160156 26.667969 230.160156 34.203125 225.351562 34.136719 Z M 225.351562 34.136719 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 225.351562 32.246094 C 223 32.277344 223 28.597656 225.351562 28.628906 C 227.703125 28.597656 227.703125 32.277344 225.351562 32.246094 Z M 225.351562 32.246094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 180.628906 94.570312 C 186.695312 94.597656 186.695312 103.804688 180.628906 103.832031 C 174.566406 103.804688 174.566406 94.597656 180.628906 94.570312 Z M 180.628906 94.570312 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 180.628906 102.664062 C 176.132812 102.726562 176.132812 95.679688 180.628906 95.742188 C 185.125 95.679688 185.125 102.726562 180.628906 102.664062 Z M 180.628906 102.664062 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 180.628906 101.488281 C 177.65625 101.53125 177.65625 96.867188 180.628906 96.90625 C 183.601562 96.867188 183.601562 101.53125 180.628906 101.488281 Z M 180.628906 101.488281 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 180.628906 100.320312 C 179.171875 100.34375 179.171875 98.058594 180.628906 98.082031 C 182.082031 98.058594 182.082031 100.34375 180.628906 100.320312 Z M 180.628906 100.320312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 267.691406 81.234375 C 270.808594 81.1875 270.808594 86.074219 267.691406 86.03125 C 264.574219 86.074219 264.574219 81.1875 267.691406 81.234375 Z M 267.691406 81.234375 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 267.691406 85.421875 C 265.363281 85.453125 265.363281 81.808594 267.691406 81.839844 C 270.019531 81.808594 270.019531 85.453125 267.691406 85.421875 Z M 267.691406 85.421875 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 267.691406 84.816406 C 266.15625 84.839844 266.15625 82.425781 267.691406 82.445312 C 269.230469 82.425781 269.230469 84.839844 267.691406 84.816406 Z M 267.691406 84.816406 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 267.691406 84.210938 C 266.9375 84.21875 266.9375 83.039062 267.691406 83.050781 C 268.445312 83.042969 268.445312 84.226562 267.691406 84.210938 Z M 267.691406 84.210938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#2f2762a2a7)">
        <path
          fill="#fe2860"
          d="M 356.359375 181.984375 C 366.207031 182.15625 366.207031 196.789062 356.359375 196.953125 C 346.507812 196.78125 346.507812 182.152344 356.359375 181.984375 Z M 356.359375 181.984375 "
          fill-opacity="0.2"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#fe2860"
        d="M 356.359375 195.058594 C 349.03125 195.019531 349.039062 183.917969 356.359375 183.875 C 363.679688 183.917969 363.679688 195.019531 356.359375 195.058594 Z M 356.359375 195.058594 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 356.359375 193.167969 C 351.550781 193.234375 351.550781 185.699219 356.359375 185.765625 C 361.167969 185.699219 361.167969 193.234375 356.359375 193.167969 Z M 356.359375 193.167969 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 356.359375 191.277344 C 354.007812 191.308594 354.007812 187.628906 356.359375 187.660156 C 358.707031 187.628906 358.707031 191.308594 356.359375 191.277344 Z M 356.359375 191.277344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.199219 57.671875 C 341.046875 57.84375 341.046875 72.472656 331.199219 72.640625 C 321.347656 72.46875 321.347656 57.835938 331.199219 57.671875 Z M 331.199219 57.671875 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.199219 70.746094 C 323.871094 70.703125 323.875 59.601562 331.199219 59.5625 C 338.519531 59.601562 338.519531 70.703125 331.199219 70.746094 Z M 331.199219 70.746094 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 331.199219 68.855469 C 326.390625 68.921875 326.390625 61.386719 331.199219 61.453125 C 336.007812 61.386719 336.007812 68.921875 331.199219 68.855469 Z M 331.199219 68.855469 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 331.199219 66.964844 C 328.847656 66.996094 328.847656 63.316406 331.199219 63.347656 C 333.546875 63.316406 333.546875 66.996094 331.199219 66.964844 Z M 331.199219 66.964844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 124.996094 30.433594 C 131.621094 30.464844 131.621094 40.519531 124.996094 40.550781 C 118.371094 40.519531 118.371094 30.464844 124.996094 30.433594 Z M 124.996094 30.433594 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 124.996094 39.269531 C 120.085938 39.335938 120.085938 31.644531 124.996094 31.710938 C 129.910156 31.644531 129.910156 39.335938 124.996094 39.269531 Z M 124.996094 39.269531 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 124.996094 37.992188 C 121.75 38.039062 121.75 32.945312 124.996094 32.992188 C 128.246094 32.945312 128.246094 38.039062 124.996094 37.992188 Z M 124.996094 37.992188 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 124.996094 36.710938 C 123.410156 36.734375 123.410156 34.246094 124.996094 34.269531 C 126.585938 34.246094 126.585938 36.734375 124.996094 36.710938 Z M 124.996094 36.710938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 292.367188 51.789062 C 297.175781 51.714844 297.175781 59.257812 292.367188 59.191406 C 287.558594 59.261719 287.558594 51.71875 292.367188 51.789062 Z M 292.367188 51.789062 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 292.367188 58.25 C 288.777344 58.300781 288.777344 52.667969 292.367188 52.71875 C 295.960938 52.667969 295.960938 58.300781 292.367188 58.25 Z M 292.367188 58.25 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 292.367188 57.316406 C 289.992188 57.351562 289.992188 53.628906 292.367188 53.660156 C 294.746094 53.628906 294.746094 57.351562 292.367188 57.316406 Z M 292.367188 57.316406 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 292.367188 56.382812 C 291.207031 56.394531 291.207031 54.578125 292.367188 54.59375 C 293.53125 54.582031 293.53125 56.398438 292.367188 56.382812 Z M 292.367188 56.382812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 154.90625 147.640625 C 159.714844 147.570312 159.714844 155.109375 154.90625 155.042969 C 150.097656 155.113281 150.097656 147.574219 154.90625 147.640625 Z M 154.90625 147.640625 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 154.90625 154.105469 C 151.316406 154.15625 151.316406 148.527344 154.90625 148.578125 C 158.5 148.527344 158.5 154.15625 154.90625 154.105469 Z M 154.90625 154.105469 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 154.90625 153.171875 C 152.53125 153.203125 152.53125 149.480469 154.90625 149.511719 C 157.285156 149.480469 157.285156 153.203125 154.90625 153.171875 Z M 154.90625 153.171875 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 154.90625 152.234375 C 153.746094 152.25 153.746094 150.429688 154.90625 150.449219 C 156.070312 150.433594 156.070312 152.253906 154.90625 152.234375 Z M 154.90625 152.234375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 193.179688 223.121094 C 196.773438 223.066406 196.773438 228.707031 193.179688 228.652344 C 189.585938 228.707031 189.585938 223.066406 193.179688 223.121094 Z M 193.179688 223.121094 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 193.179688 227.953125 C 190.492188 227.988281 190.492188 223.777344 193.179688 223.820312 C 195.867188 223.785156 195.867188 227.992188 193.179688 227.953125 Z M 193.179688 227.953125 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 193.179688 227.253906 C 191.402344 227.28125 191.398438 224.492188 193.179688 224.511719 C 194.960938 224.488281 194.960938 227.277344 193.179688 227.253906 Z M 193.179688 227.253906 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 193.179688 226.550781 C 192.308594 226.566406 192.308594 225.203125 193.179688 225.210938 C 194.050781 225.199219 194.050781 226.558594 193.179688 226.550781 Z M 193.179688 226.550781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 189.929688 12.136719 C 194.738281 12.0625 194.738281 19.605469 189.929688 19.539062 C 185.121094 19.609375 185.121094 12.066406 189.929688 12.136719 Z M 189.929688 12.136719 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 189.929688 18.597656 C 186.335938 18.648438 186.335938 13.015625 189.929688 13.066406 C 193.519531 13.015625 193.519531 18.648438 189.929688 18.597656 Z M 189.929688 18.597656 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 189.929688 17.660156 C 187.550781 17.691406 187.550781 13.972656 189.929688 14.003906 C 192.304688 13.972656 192.304688 17.691406 189.929688 17.660156 Z M 189.929688 17.660156 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 189.929688 16.730469 C 188.765625 16.742188 188.765625 14.925781 189.929688 14.941406 C 191.089844 14.929688 191.089844 16.746094 189.929688 16.730469 Z M 189.929688 16.730469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 226.253906 68.214844 C 233.714844 68.246094 233.714844 79.570312 226.253906 79.601562 C 218.792969 79.570312 218.792969 68.246094 226.253906 68.214844 Z M 226.253906 68.214844 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 226.253906 78.171875 C 220.726562 78.246094 220.726562 69.585938 226.253906 69.660156 C 231.78125 69.585938 231.78125 78.246094 226.253906 78.171875 Z M 226.253906 78.171875 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 226.253906 76.726562 C 222.59375 76.777344 222.59375 71.039062 226.253906 71.09375 C 229.914062 71.042969 229.914062 76.78125 226.253906 76.726562 Z M 226.253906 76.726562 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 226.253906 75.289062 C 224.464844 75.3125 224.464844 72.507812 226.253906 72.535156 C 228.042969 72.515625 228.042969 75.316406 226.253906 75.289062 Z M 226.253906 75.289062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 294.429688 73.632812 C 304.277344 73.804688 304.277344 88.433594 294.429688 88.601562 C 284.578125 88.429688 284.578125 73.800781 294.429688 73.632812 Z M 294.429688 73.632812 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 294.429688 86.707031 C 287.101562 86.664062 287.105469 75.5625 294.429688 75.523438 C 301.75 75.5625 301.75 86.664062 294.429688 86.707031 Z M 294.429688 86.707031 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 294.429688 84.816406 C 289.621094 84.882812 289.621094 77.347656 294.429688 77.414062 C 299.238281 77.347656 299.238281 84.882812 294.429688 84.816406 Z M 294.429688 84.816406 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 294.429688 82.925781 C 292.078125 82.957031 292.078125 79.277344 294.429688 79.308594 C 296.777344 79.277344 296.777344 82.957031 294.429688 82.925781 Z M 294.429688 82.925781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 314.570312 101.296875 C 320.679688 101.324219 320.679688 110.597656 314.570312 110.625 C 308.460938 110.597656 308.460938 101.324219 314.570312 101.296875 Z M 314.570312 101.296875 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 314.570312 109.449219 C 310.042969 109.511719 310.039062 102.414062 314.570312 102.476562 C 319.097656 102.414062 319.097656 109.511719 314.570312 109.449219 Z M 314.570312 109.449219 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 314.570312 108.269531 C 311.574219 108.308594 311.574219 103.613281 314.570312 103.652344 C 317.566406 103.613281 317.566406 108.308594 314.570312 108.269531 Z M 314.570312 108.269531 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 314.570312 107.085938 C 313.101562 107.105469 313.101562 104.808594 314.570312 104.832031 C 316.035156 104.8125 316.035156 107.109375 314.570312 107.085938 Z M 314.570312 107.085938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 139.441406 90.75 C 146.355469 90.78125 146.355469 101.273438 139.441406 101.304688 C 132.527344 101.273438 132.527344 90.78125 139.441406 90.75 Z M 139.441406 90.75 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 139.441406 99.972656 C 134.3125 100.042969 134.320312 92.011719 139.441406 92.085938 C 144.5625 92.011719 144.5625 100.042969 139.441406 99.972656 Z M 139.441406 99.972656 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 139.441406 98.640625 C 136.050781 98.691406 136.050781 93.375 139.441406 93.425781 C 142.832031 93.375 142.832031 98.691406 139.441406 98.640625 Z M 139.441406 98.640625 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 139.441406 97.308594 C 137.78125 97.328125 137.78125 94.730469 139.441406 94.753906 C 141.101562 94.726562 141.105469 97.332031 139.441406 97.308594 Z M 139.441406 97.308594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 150.320312 40.652344 C 155.011719 40.585938 155.011719 47.941406 150.320312 47.875 C 145.628906 47.941406 145.628906 40.585938 150.320312 40.652344 Z M 150.320312 40.652344 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 150.320312 46.964844 C 146.816406 47.011719 146.816406 41.523438 150.320312 41.566406 C 153.824219 41.519531 153.824219 47.007812 150.320312 46.964844 Z M 150.320312 46.964844 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 150.320312 46.050781 C 148 46.082031 148 42.445312 150.320312 42.480469 C 152.636719 42.449219 152.636719 46.085938 150.320312 46.050781 Z M 150.320312 46.050781 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 150.320312 45.136719 C 149.183594 45.148438 149.183594 43.371094 150.320312 43.390625 C 151.453125 43.375 151.453125 45.15625 150.320312 45.136719 Z M 150.320312 45.136719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 196.054688 31.917969 C 205.160156 31.957031 205.160156 45.78125 196.054688 45.820312 C 186.949219 45.78125 186.949219 31.957031 196.054688 31.917969 Z M 196.054688 31.917969 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 196.054688 44.0625 C 189.25 44.027344 189.25 33.703125 196.054688 33.671875 C 202.859375 33.707031 202.859375 44.03125 196.054688 44.0625 Z M 196.054688 44.0625 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 196.054688 42.304688 C 191.585938 42.371094 191.585938 35.363281 196.054688 35.425781 C 200.523438 35.363281 200.523438 42.371094 196.054688 42.304688 Z M 196.054688 42.304688 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 196.054688 40.550781 C 193.871094 40.582031 193.867188 37.152344 196.054688 37.183594 C 198.246094 37.152344 198.246094 40.582031 196.054688 40.550781 Z M 196.054688 40.550781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 107.566406 62.316406 C 112.257812 62.25 112.257812 69.601562 107.566406 69.535156 C 102.875 69.601562 102.875 62.25 107.566406 62.316406 Z M 107.566406 62.316406 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 107.566406 68.621094 C 104.0625 68.671875 104.0625 63.179688 107.566406 63.226562 C 111.070312 63.175781 111.070312 68.667969 107.566406 68.621094 Z M 107.566406 68.621094 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 107.566406 67.714844 C 105.246094 67.746094 105.246094 64.109375 107.566406 64.144531 C 109.882812 64.113281 109.882812 67.75 107.566406 67.714844 Z M 107.566406 67.714844 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 107.566406 66.800781 C 106.429688 66.8125 106.429688 65.035156 107.566406 65.050781 C 108.699219 65.039062 108.699219 66.816406 107.566406 66.800781 Z M 107.566406 66.800781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 187.226562 189.464844 C 199.425781 189.671875 199.421875 207.785156 187.226562 207.992188 C 175.03125 207.785156 175.035156 189.671875 187.226562 189.464844 Z M 187.226562 189.464844 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 187.226562 205.648438 C 178.160156 205.601562 178.160156 191.851562 187.226562 191.800781 C 196.292969 191.851562 196.292969 205.601562 187.226562 205.648438 Z M 187.226562 205.648438 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 187.226562 203.3125 C 181.273438 203.394531 181.28125 194.070312 187.226562 194.152344 C 193.175781 194.074219 193.175781 203.398438 187.226562 203.3125 Z M 187.226562 203.3125 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 187.226562 200.96875 C 184.316406 201.011719 184.316406 196.453125 187.226562 196.492188 C 190.140625 196.453125 190.140625 201.011719 187.226562 200.96875 Z M 187.226562 200.96875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 275.066406 202.285156 C 287.703125 202.5 287.703125 221.265625 275.066406 221.480469 C 262.429688 221.265625 262.4375 202.5 275.066406 202.285156 Z M 275.066406 202.285156 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 275.066406 218.65625 C 266.199219 218.605469 266.203125 205.160156 275.066406 205.113281 C 283.9375 205.160156 283.929688 218.605469 275.066406 218.65625 Z M 275.066406 218.65625 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 275.066406 215.824219 C 269.949219 215.898438 269.949219 207.871094 275.066406 207.941406 C 280.1875 207.871094 280.1875 215.898438 275.066406 215.824219 Z M 275.066406 215.824219 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 275.066406 213 C 273.625 213.023438 273.617188 210.75 275.066406 210.769531 C 276.515625 210.746094 276.515625 213.015625 275.066406 213 Z M 275.066406 213 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 290.8125 270.546875 C 300.027344 270.589844 300.027344 284.578125 290.8125 284.613281 C 281.597656 284.574219 281.597656 270.582031 290.8125 270.546875 Z M 290.8125 270.546875 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 290.8125 282.542969 C 284.316406 282.503906 284.316406 272.652344 290.8125 272.617188 C 297.308594 272.652344 297.308594 282.503906 290.8125 282.542969 Z M 290.8125 282.542969 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 290.8125 280.46875 C 287.058594 280.515625 287.058594 274.640625 290.8125 274.691406 C 294.5625 274.636719 294.5625 280.515625 290.8125 280.46875 Z M 290.8125 280.46875 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 290.8125 278.394531 C 289.753906 278.40625 289.753906 276.75 290.8125 276.765625 C 291.871094 276.75 291.871094 278.40625 290.8125 278.394531 Z M 290.8125 278.394531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 225.285156 196.488281 C 242.933594 196.789062 242.925781 222.996094 225.285156 223.296875 C 207.636719 222.996094 207.640625 196.789062 225.285156 196.488281 Z M 225.285156 196.488281 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 225.285156 220.429688 C 211.410156 220.183594 211.414062 199.59375 225.285156 199.351562 C 239.160156 199.601562 239.15625 220.1875 225.285156 220.429688 Z M 225.285156 220.429688 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 225.285156 217.566406 C 215.179688 217.386719 215.183594 202.390625 225.285156 202.214844 C 235.390625 202.394531 235.386719 217.394531 225.285156 217.566406 Z M 225.285156 217.566406 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 225.285156 214.707031 C 218.980469 214.675781 218.980469 205.113281 225.285156 205.082031 C 231.585938 205.113281 231.585938 214.675781 225.285156 214.707031 Z M 225.285156 214.707031 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 225.285156 211.84375 C 222.75 211.878906 222.75 207.90625 225.285156 207.941406 C 227.820312 207.90625 227.820312 211.878906 225.285156 211.84375 Z M 225.285156 211.84375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 172.300781 69.261719 C 180.203125 69.296875 180.203125 81.296875 172.300781 81.332031 C 164.394531 81.296875 164.394531 69.296875 172.300781 69.261719 Z M 172.300781 69.261719 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 172.300781 80.046875 C 166.082031 80.015625 166.082031 70.582031 172.300781 70.558594 C 178.515625 70.589844 178.507812 80.015625 172.300781 80.046875 Z M 172.300781 80.046875 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 172.300781 78.753906 C 167.808594 78.816406 167.808594 71.773438 172.300781 71.835938 C 176.789062 71.773438 176.789062 78.816406 172.300781 78.753906 Z M 172.300781 78.753906 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 172.300781 77.460938 C 169.480469 77.5 169.488281 73.089844 172.300781 73.128906 C 175.113281 73.089844 175.113281 77.5 172.300781 77.460938 Z M 172.300781 77.460938 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 172.300781 76.175781 C 171.160156 76.195312 171.160156 74.410156 172.300781 74.421875 C 173.4375 74.402344 173.4375 76.191406 172.300781 76.175781 Z M 172.300781 76.175781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 238.59375 123.34375 C 250.578125 123.550781 250.578125 141.351562 238.59375 141.554688 C 226.609375 141.347656 226.609375 123.546875 238.59375 123.34375 Z M 238.59375 123.34375 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 238.597656 139.605469 C 229.222656 139.558594 229.21875 125.332031 238.597656 125.285156 C 247.976562 125.332031 247.972656 139.558594 238.597656 139.605469 Z M 238.597656 139.605469 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 238.597656 137.664062 C 231.769531 137.625 231.769531 127.273438 238.597656 127.238281 C 245.425781 127.273438 245.425781 137.625 238.597656 137.664062 Z M 238.597656 137.664062 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 238.597656 135.71875 C 234.351562 135.777344 234.351562 129.121094 238.597656 129.179688 C 242.847656 129.121094 242.847656 135.777344 238.597656 135.71875 Z M 238.597656 135.71875 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 238.597656 133.773438 C 236.875 133.796875 236.875 131.101562 238.597656 131.125 C 240.320312 131.101562 240.320312 133.796875 238.597656 133.773438 Z M 238.597656 133.773438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 92.167969 C 267.566406 92.207031 267.566406 104.410156 259.527344 104.445312 C 251.492188 104.410156 251.492188 92.207031 259.527344 92.167969 Z M 259.527344 92.167969 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 103.132812 C 253.207031 103.101562 253.207031 93.511719 259.527344 93.480469 C 265.847656 93.511719 265.847656 103.101562 259.527344 103.132812 Z M 259.527344 103.132812 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 101.820312 C 254.960938 101.882812 254.960938 94.726562 259.527344 94.789062 C 264.09375 94.726562 264.09375 101.882812 259.527344 101.820312 Z M 259.527344 101.820312 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 100.511719 C 256.664062 100.554688 256.664062 96.0625 259.527344 96.101562 C 262.394531 96.0625 262.394531 100.554688 259.527344 100.511719 Z M 259.527344 100.511719 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 259.527344 99.199219 C 258.367188 99.214844 258.367188 97.394531 259.527344 97.414062 C 260.691406 97.402344 260.683594 99.214844 259.527344 99.199219 Z M 259.527344 99.199219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 48.648438 C 270.152344 48.828125 270.148438 64.601562 259.527344 64.78125 C 248.90625 64.601562 248.910156 48.828125 259.527344 48.648438 Z M 259.527344 48.648438 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 63.058594 C 251.222656 63.015625 251.21875 50.414062 259.527344 50.371094 C 267.835938 50.417969 267.832031 63.015625 259.527344 63.058594 Z M 259.527344 63.058594 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 61.335938 C 253.476562 61.304688 253.476562 52.125 259.527344 52.09375 C 265.582031 52.125 265.582031 61.304688 259.527344 61.335938 Z M 259.527344 61.335938 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 259.527344 59.613281 C 255.765625 59.664062 255.765625 53.761719 259.527344 53.816406 C 263.292969 53.761719 263.292969 59.664062 259.527344 59.613281 Z M 259.527344 59.613281 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 259.527344 57.886719 C 258.003906 57.90625 258.007812 55.523438 259.527344 55.539062 C 261.050781 55.519531 261.054688 57.910156 259.527344 57.886719 Z M 259.527344 57.886719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 261.734375 12.183594 C 267.640625 12.097656 267.640625 21.355469 261.734375 21.265625 C 255.832031 21.351562 255.832031 12.09375 261.734375 12.183594 Z M 261.734375 12.183594 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 261.734375 20.300781 C 257.09375 20.367188 257.09375 13.089844 261.734375 13.15625 C 266.375 13.089844 266.375 20.367188 261.734375 20.300781 Z M 261.734375 20.300781 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 261.734375 19.328125 C 258.359375 19.371094 258.359375 14.078125 261.734375 14.125 C 265.113281 14.078125 265.113281 19.371094 261.734375 19.328125 Z M 261.734375 19.328125 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 261.734375 18.359375 C 259.617188 18.390625 259.617188 15.070312 261.734375 15.101562 C 263.851562 15.070312 263.851562 18.390625 261.734375 18.359375 Z M 261.734375 18.359375 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 261.734375 17.394531 C 260.878906 17.40625 260.878906 16.0625 261.734375 16.070312 C 262.59375 16.058594 262.59375 17.402344 261.734375 17.394531 Z M 261.734375 17.394531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.671875 175.15625 C 174.785156 175.328125 174.785156 190.34375 164.671875 190.515625 C 154.5625 190.347656 154.5625 175.332031 164.671875 175.15625 Z M 164.671875 175.15625 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.671875 188.875 C 156.761719 188.832031 156.765625 176.832031 164.671875 176.789062 C 172.582031 176.832031 172.582031 188.832031 164.671875 188.875 Z M 164.671875 188.875 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.671875 187.234375 C 158.957031 187.316406 158.957031 178.359375 164.671875 178.4375 C 170.386719 178.359375 170.386719 187.316406 164.671875 187.234375 Z M 164.671875 187.234375 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.671875 185.589844 C 161.089844 185.640625 161.089844 180.019531 164.671875 180.074219 C 168.257812 180.023438 168.257812 185.644531 164.671875 185.589844 Z M 164.671875 185.589844 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 164.671875 183.953125 C 163.21875 183.96875 163.21875 181.699219 164.671875 181.71875 C 166.125 181.699219 166.125 183.96875 164.671875 183.953125 Z M 164.671875 183.953125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 350.761719 128.097656 C 360.792969 128.265625 360.792969 143.171875 350.761719 143.335938 C 340.726562 143.167969 340.730469 128.261719 350.761719 128.097656 Z M 350.761719 128.097656 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 350.761719 141.710938 C 342.910156 141.671875 342.910156 129.761719 350.761719 129.722656 C 358.609375 129.761719 358.609375 141.671875 350.761719 141.710938 Z M 350.761719 141.710938 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 350.761719 140.082031 C 345.089844 140.15625 345.09375 131.277344 350.761719 131.351562 C 356.429688 131.277344 356.429688 140.15625 350.761719 140.082031 Z M 350.761719 140.082031 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 350.761719 138.453125 C 347.203125 138.503906 347.207031 132.933594 350.761719 132.980469 C 354.3125 132.933594 354.3125 138.503906 350.761719 138.453125 Z M 350.761719 138.453125 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 350.761719 136.824219 C 349.320312 136.84375 349.320312 134.589844 350.761719 134.609375 C 352.199219 134.589844 352.199219 136.84375 350.761719 136.824219 Z M 350.761719 136.824219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 335.25 91.199219 C 341.152344 91.113281 341.152344 100.371094 335.25 100.28125 C 329.34375 100.367188 329.34375 91.109375 335.25 91.199219 Z M 335.25 91.199219 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 335.25 99.308594 C 330.605469 99.375 330.605469 92.097656 335.25 92.164062 C 339.886719 92.097656 339.886719 99.375 335.25 99.308594 Z M 335.25 99.308594 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 335.25 98.339844 C 331.871094 98.386719 331.871094 93.09375 335.25 93.136719 C 338.625 93.09375 338.625 98.386719 335.25 98.339844 Z M 335.25 98.339844 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 335.25 97.371094 C 333.132812 97.402344 333.132812 94.078125 335.25 94.109375 C 337.367188 94.078125 337.367188 97.402344 335.25 97.371094 Z M 335.25 97.371094 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 335.25 96.402344 C 334.390625 96.414062 334.390625 95.070312 335.25 95.082031 C 336.105469 95.066406 336.105469 96.410156 335.25 96.402344 Z M 335.25 96.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 134.15625 69.878906 C 140.058594 69.796875 140.058594 79.050781 134.15625 78.960938 C 128.253906 79.046875 128.253906 69.789062 134.15625 69.878906 Z M 134.15625 69.878906 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 134.15625 77.992188 C 129.515625 78.058594 129.515625 70.78125 134.15625 70.847656 C 138.796875 70.78125 138.796875 78.058594 134.15625 77.992188 Z M 134.15625 77.992188 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 134.15625 77.023438 C 130.777344 77.070312 130.777344 71.773438 134.15625 71.820312 C 137.535156 71.773438 137.535156 77.070312 134.15625 77.023438 Z M 134.15625 77.023438 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 134.15625 76.050781 C 132.039062 76.082031 132.039062 72.761719 134.15625 72.792969 C 136.273438 72.761719 136.273438 76.082031 134.15625 76.050781 Z M 134.15625 76.050781 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 134.15625 75.078125 C 133.300781 75.09375 133.300781 73.75 134.15625 73.757812 C 135.015625 73.746094 135.015625 75.089844 134.15625 75.078125 Z M 134.15625 75.078125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.472656 27.210938 C 302.292969 27.394531 302.285156 43.457031 291.472656 43.640625 C 280.652344 43.457031 280.65625 27.394531 291.472656 27.210938 Z M 291.472656 27.210938 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.472656 41.890625 C 283.007812 41.84375 283.007812 29.011719 291.472656 28.964844 C 299.9375 29.011719 299.933594 41.84375 291.472656 41.890625 Z M 291.472656 41.890625 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.472656 40.132812 C 285.308594 40.101562 285.308594 30.753906 291.472656 30.71875 C 297.636719 30.753906 297.636719 40.101562 291.472656 40.132812 Z M 291.472656 40.132812 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 291.472656 38.378906 C 287.640625 38.433594 287.640625 32.421875 291.472656 32.480469 C 295.300781 32.425781 295.300781 38.4375 291.472656 38.378906 Z M 291.472656 38.378906 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 291.472656 36.625 C 289.914062 36.644531 289.914062 34.210938 291.472656 34.230469 C 293.023438 34.210938 293.023438 36.644531 291.472656 36.625 Z M 291.472656 36.625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#a735767946)">
        <path
          fill="#0086ff"
          d="M 164.144531 8.511719 C 174.964844 8.695312 174.960938 24.761719 164.144531 24.945312 C 153.324219 24.761719 153.328125 8.695312 164.144531 8.511719 Z M 164.144531 8.511719 "
          fill-opacity="0.2"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#0086ff"
        d="M 164.144531 23.191406 C 155.679688 23.148438 155.679688 10.3125 164.144531 10.265625 C 172.609375 10.3125 172.605469 23.148438 164.144531 23.191406 Z M 164.144531 23.191406 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.144531 21.433594 C 157.980469 21.402344 157.980469 12.050781 164.144531 12.019531 C 170.308594 12.050781 170.308594 21.402344 164.144531 21.433594 Z M 164.144531 21.433594 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 164.144531 19.679688 C 160.3125 19.734375 160.3125 13.726562 164.144531 13.78125 C 167.976562 13.730469 167.976562 19.738281 164.144531 19.679688 Z M 164.144531 19.679688 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 164.144531 17.925781 C 162.585938 17.949219 162.585938 15.511719 164.144531 15.535156 C 165.695312 15.511719 165.695312 17.949219 164.144531 17.925781 Z M 164.144531 17.925781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 304.945312 159.269531 C 309.367188 159.207031 309.367188 166.140625 304.945312 166.074219 C 300.527344 166.136719 300.527344 159.203125 304.945312 159.269531 Z M 304.945312 159.269531 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 304.945312 165.347656 C 301.46875 165.398438 301.46875 159.953125 304.945312 160 C 308.421875 159.953125 308.421875 165.398438 304.945312 165.347656 Z M 304.945312 165.347656 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 304.945312 164.617188 C 302.417969 164.652344 302.414062 160.683594 304.945312 160.71875 C 307.480469 160.683594 307.480469 164.652344 304.945312 164.617188 Z M 304.945312 164.617188 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 304.945312 163.894531 C 303.359375 163.914062 303.359375 161.425781 304.945312 161.449219 C 306.535156 161.425781 306.535156 163.914062 304.945312 163.894531 Z M 304.945312 163.894531 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 304.945312 163.167969 C 304.304688 163.175781 304.304688 162.167969 304.945312 162.175781 C 305.589844 162.167969 305.589844 163.175781 304.945312 163.167969 Z M 304.945312 163.167969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 248.597656 224.378906 C 256.027344 224.410156 256.027344 235.6875 248.597656 235.722656 C 241.167969 235.691406 241.167969 224.414062 248.597656 224.378906 Z M 248.597656 224.378906 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 248.597656 234.507812 C 242.800781 234.589844 242.800781 225.507812 248.597656 225.589844 C 254.394531 225.507812 254.394531 234.589844 248.597656 234.507812 Z M 248.597656 234.507812 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 248.597656 233.296875 C 244.375 233.355469 244.375 226.738281 248.597656 226.796875 C 252.816406 226.738281 252.816406 233.355469 248.597656 233.296875 Z M 248.597656 233.296875 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 248.597656 232.082031 C 245.949219 232.117188 245.953125 227.976562 248.597656 228.011719 C 251.238281 227.976562 251.238281 232.117188 248.597656 232.082031 Z M 248.597656 232.082031 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 248.597656 230.871094 C 247.523438 230.886719 247.523438 229.210938 248.597656 229.226562 C 249.667969 229.210938 249.667969 230.886719 248.597656 230.871094 Z M 248.597656 230.871094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 193.179688 117.671875 C 200.609375 117.703125 200.609375 128.980469 193.179688 129.015625 C 185.75 128.984375 185.75 117.707031 193.179688 117.671875 Z M 193.179688 117.671875 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 193.179688 127.800781 C 187.382812 127.882812 187.382812 118.800781 193.179688 118.878906 C 198.976562 118.800781 198.976562 127.882812 193.179688 127.800781 Z M 193.179688 127.800781 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 193.179688 126.589844 C 188.960938 126.648438 188.960938 120.035156 193.179688 120.09375 C 197.402344 120.035156 197.402344 126.648438 193.179688 126.589844 Z M 193.179688 126.589844 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 193.179688 125.382812 C 190.535156 125.417969 190.539062 121.277344 193.179688 121.3125 C 195.824219 121.277344 195.824219 125.417969 193.179688 125.382812 Z M 193.179688 125.382812 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 193.179688 124.167969 C 192.109375 124.183594 192.109375 122.507812 193.179688 122.519531 C 194.253906 122.507812 194.253906 124.183594 193.179688 124.167969 Z M 193.179688 124.167969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 229.957031 89.375 C 233.480469 89.324219 233.480469 94.847656 229.957031 94.792969 C 226.4375 94.84375 226.4375 89.320312 229.957031 89.375 Z M 229.957031 89.375 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 229.957031 94.210938 C 227.195312 94.246094 227.191406 89.917969 229.957031 89.953125 C 232.722656 89.917969 232.722656 94.246094 229.957031 94.210938 Z M 229.957031 94.210938 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 229.957031 93.632812 C 227.945312 93.660156 227.945312 90.503906 229.957031 90.53125 C 231.972656 90.503906 231.972656 93.660156 229.957031 93.632812 Z M 229.957031 93.632812 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 229.957031 93.054688 C 228.695312 93.074219 228.695312 91.089844 229.957031 91.109375 C 231.222656 91.089844 231.222656 93.074219 229.957031 93.054688 Z M 229.957031 93.054688 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 229.957031 92.480469 C 229.449219 92.488281 229.449219 91.6875 229.957031 91.695312 C 230.46875 91.6875 230.46875 92.488281 229.957031 92.480469 Z M 229.957031 92.480469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 325.335938 128.363281 C 330.210938 128.292969 330.210938 135.941406 325.335938 135.871094 C 320.457031 135.941406 320.457031 128.292969 325.335938 128.363281 Z M 325.335938 128.363281 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 325.335938 135.070312 C 321.5 135.121094 321.5 129.113281 325.335938 129.164062 C 329.171875 129.109375 329.171875 135.117188 325.335938 135.070312 Z M 325.335938 135.070312 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 325.335938 134.269531 C 322.542969 134.304688 322.542969 129.933594 325.335938 129.96875 C 328.125 129.929688 328.125 134.304688 325.335938 134.269531 Z M 325.335938 134.269531 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#0086ff"
        d="M 325.335938 133.464844 C 323.585938 133.492188 323.582031 130.742188 325.335938 130.769531 C 327.089844 130.742188 327.089844 133.492188 325.335938 133.464844 Z M 325.335938 133.464844 "
        fill-opacity="0.8"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 325.335938 132.664062 C 324.625 132.671875 324.625 131.566406 325.335938 131.570312 C 326.042969 131.5625 326.042969 132.671875 325.335938 132.664062 Z M 325.335938 132.664062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 245.515625 255.632812 C 260.957031 255.898438 260.949219 278.824219 245.515625 279.089844 C 230.074219 278.824219 230.078125 255.898438 245.515625 255.632812 Z M 245.515625 255.632812 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 245.515625 275.683594 C 234.558594 275.488281 234.5625 259.230469 245.515625 259.035156 C 256.472656 259.230469 256.46875 275.488281 245.515625 275.683594 Z M 245.515625 275.683594 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 245.515625 272.28125 C 239.070312 272.25 239.070312 262.46875 245.515625 262.433594 C 251.960938 262.464844 251.960938 272.246094 245.515625 272.28125 Z M 245.515625 272.28125 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 245.515625 268.878906 C 243.542969 268.902344 243.542969 265.808594 245.515625 265.835938 C 247.488281 265.808594 247.488281 268.902344 245.515625 268.878906 Z M 245.515625 268.878906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 218.648438 240.605469 C 226.042969 240.636719 226.042969 251.863281 218.648438 251.894531 C 211.257812 251.863281 211.257812 240.636719 218.648438 240.605469 Z M 218.648438 240.605469 "
        fill-opacity="0.2"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 218.648438 250.253906 C 213.445312 250.328125 213.445312 242.171875 218.648438 242.242188 C 223.855469 242.171875 223.855469 250.328125 218.648438 250.253906 Z M 218.648438 250.253906 "
        fill-opacity="0.4"
        fill-rule="nonzero"
      />
      <path
        fill="#fe2860"
        d="M 218.648438 248.617188 C 215.574219 248.660156 215.570312 243.832031 218.648438 243.878906 C 221.726562 243.832031 221.726562 248.660156 218.648438 248.617188 Z M 218.648438 248.617188 "
        fill-opacity="0.6"
        fill-rule="nonzero"
      />
      <path
        fill="#ffffff"
        d="M 218.648438 246.976562 C 217.699219 246.992188 217.703125 245.5 218.648438 245.511719 C 219.601562 245.5 219.597656 246.988281 218.648438 246.976562 Z M 218.648438 246.976562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
}

function IconVercel({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      aria-label="Vercel logomark"
      role="img"
      viewBox="0 0 74 64"
      className={cn("size-4", className)}
      {...props}
    >
      <path
        d="M37.5896 0.25L74.5396 64.25H0.639648L37.5896 0.25Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

function IconGitHub({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <title>GitHub</title>
      <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
    </svg>
  );
}

function IconSeparator({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      fill="none"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      viewBox="0 0 24 24"
      aria-hidden="true"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M16.88 3.549L7.12 20.451"></path>
    </svg>
  );
}

function IconArrowDown({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="m205.66 149.66-72 72a8 8 0 0 1-11.32 0l-72-72a8 8 0 0 1 11.32-11.32L120 196.69V40a8 8 0 0 1 16 0v156.69l58.34-58.35a8 8 0 0 1 11.32 11.32Z" />
    </svg>
  );
}

function IconArrowRight({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="m221.66 133.66-72 72a8 8 0 0 1-11.32-11.32L196.69 136H40a8 8 0 0 1 0-16h156.69l-58.35-58.34a8 8 0 0 1 11.32-11.32l72 72a8 8 0 0 1 0 11.32Z" />
    </svg>
  );
}

function IconUser({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z" />
    </svg>
  );
}

function IconPlus({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M224 128a8 8 0 0 1-8 8h-80v80a8 8 0 0 1-16 0v-80H40a8 8 0 0 1 0-16h80V40a8 8 0 0 1 16 0v80h80a8 8 0 0 1 8 8Z" />
    </svg>
  );
}

function IconArrowElbow({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M200 32v144a8 8 0 0 1-8 8H67.31l34.35 34.34a8 8 0 0 1-11.32 11.32l-48-48a8 8 0 0 1 0-11.32l48-48a8 8 0 0 1 11.32 11.32L67.31 168H184V32a8 8 0 0 1 16 0Z" />
    </svg>
  );
}

function IconSpinner({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4 animate-spin", className)}
      {...props}
    >
      <path d="M232 128a104 104 0 0 1-208 0c0-41 23.81-78.36 60.66-95.27a8 8 0 0 1 6.68 14.54C60.15 61.59 40 93.27 40 128a88 88 0 0 0 176 0c0-34.73-20.15-66.41-51.34-80.73a8 8 0 0 1 6.68-14.54C208.19 49.64 232 87 232 128Z" />
    </svg>
  );
}

function IconMessage({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M216 48H40a16 16 0 0 0-16 16v160a15.84 15.84 0 0 0 9.25 14.5A16.05 16.05 0 0 0 40 240a15.89 15.89 0 0 0 10.25-3.78.69.69 0 0 0 .13-.11L82.5 208H216a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16ZM40 224Zm176-32H82.5a16 16 0 0 0-10.3 3.75l-.12.11L40 224V64h176Z" />
    </svg>
  );
}

function IconTrash({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16ZM96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Zm48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0Z" />
    </svg>
  );
}

function IconRefresh({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M197.67 186.37a8 8 0 0 1 0 11.29C196.58 198.73 170.82 224 128 224c-37.39 0-64.53-22.4-80-39.85V208a8 8 0 0 1-16 0v-48a8 8 0 0 1 8-8h48a8 8 0 0 1 0 16H55.44C67.76 183.35 93 208 128 208c36 0 58.14-21.46 58.36-21.68a8 8 0 0 1 11.31.05ZM216 40a8 8 0 0 0-8 8v23.85C192.53 54.4 165.39 32 128 32c-42.82 0-68.58 25.27-69.66 26.34a8 8 0 0 0 11.3 11.34C69.86 69.46 92 48 128 48c35 0 60.24 24.65 72.56 40H168a8 8 0 0 0 0 16h48a8 8 0 0 0 8-8V48a8 8 0 0 0-8-8Z" />
    </svg>
  );
}

function IconStop({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm24-120h-48a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8Zm-8 48h-32v-32h32Z" />
    </svg>
  );
}

function IconSidebar({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h40v144H40Zm176 144H96V56h120v144Z" />
    </svg>
  );
}

function IconMoon({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M233.54 142.23a8 8 0 0 0-8-2 88.08 88.08 0 0 1-109.8-109.8 8 8 0 0 0-10-10 104.84 104.84 0 0 0-52.91 37A104 104 0 0 0 136 224a103.09 103.09 0 0 0 62.52-20.88 104.84 104.84 0 0 0 37-52.91 8 8 0 0 0-1.98-7.98Zm-44.64 48.11A88 88 0 0 1 65.66 67.11a89 89 0 0 1 31.4-26A106 106 0 0 0 96 56a104.11 104.11 0 0 0 104 104 106 106 0 0 0 14.92-1.06 89 89 0 0 1-26.02 31.4Z" />
    </svg>
  );
}

function IconSun({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M120 40V16a8 8 0 0 1 16 0v24a8 8 0 0 1-16 0Zm72 88a64 64 0 1 1-64-64 64.07 64.07 0 0 1 64 64Zm-16 0a48 48 0 1 0-48 48 48.05 48.05 0 0 0 48-48ZM58.34 69.66a8 8 0 0 0 11.32-11.32l-16-16a8 8 0 0 0-11.32 11.32Zm0 116.68-16 16a8 8 0 0 0 11.32 11.32l16-16a8 8 0 0 0-11.32-11.32ZM192 72a8 8 0 0 0 5.66-2.34l16-16a8 8 0 0 0-11.32-11.32l-16 16A8 8 0 0 0 192 72Zm5.66 114.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32-11.32ZM48 128a8 8 0 0 0-8-8H16a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8Zm80 80a8 8 0 0 0-8 8v24a8 8 0 0 0 16 0v-24a8 8 0 0 0-8-8Zm112-88h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Z" />
    </svg>
  );
}

function IconCopy({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8Zm-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z" />
    </svg>
  );
}

function IconCheck({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="m229.66 77.66-128 128a8 8 0 0 1-11.32 0l-56-56a8 8 0 0 1 11.32-11.32L96 188.69 218.34 66.34a8 8 0 0 1 11.32 11.32Z" />
    </svg>
  );
}

function IconDownload({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M224 152v56a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16v-56a8 8 0 0 1 16 0v56h160v-56a8 8 0 0 1 16 0Zm-101.66 5.66a8 8 0 0 0 11.32 0l40-40a8 8 0 0 0-11.32-11.32L136 132.69V40a8 8 0 0 0-16 0v92.69l-26.34-26.35a8 8 0 0 0-11.32 11.32Z" />
    </svg>
  );
}

function IconClose({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path d="M205.66 194.34a8 8 0 0 1-11.32 11.32L128 139.31l-66.34 66.35a8 8 0 0 1-11.32-11.32L116.69 128 50.34 61.66a8 8 0 0 1 11.32-11.32L128 116.69l66.34-66.35a8 8 0 0 1 11.32 11.32L139.31 128Z" />
    </svg>
  );
}

function IconEdit({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={cn("size-4", className)}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
      />
    </svg>
  );
}

function IconShare({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={cn("size-4", className)}
      viewBox="0 0 256 256"
      {...props}
    >
      <path d="m237.66 106.35-80-80A8 8 0 0 0 144 32v40.35c-25.94 2.22-54.59 14.92-78.16 34.91-28.38 24.08-46.05 55.11-49.76 87.37a12 12 0 0 0 20.68 9.58c11-11.71 50.14-48.74 107.24-52V192a8 8 0 0 0 13.66 5.65l80-80a8 8 0 0 0 0-11.3ZM160 172.69V144a8 8 0 0 0-8-8c-28.08 0-55.43 7.33-81.29 21.8a196.17 196.17 0 0 0-36.57 26.52c5.8-23.84 20.42-46.51 42.05-64.86C99.41 99.77 127.75 88 152 88a8 8 0 0 0 8-8V51.32L220.69 112Z" />
    </svg>
  );
}

function IconUsers({ className, ...props }: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={cn("size-4", className)}
      viewBox="0 0 256 256"
      {...props}
    >
      <path d="M117.25 157.92a60 60 0 1 0-66.5 0 95.83 95.83 0 0 0-47.22 37.71 8 8 0 1 0 13.4 8.74 80 80 0 0 1 134.14 0 8 8 0 0 0 13.4-8.74 95.83 95.83 0 0 0-47.22-37.71ZM40 108a44 44 0 1 1 44 44 44.05 44.05 0 0 1-44-44Zm210.14 98.7a8 8 0 0 1-11.07-2.33A79.83 79.83 0 0 0 172 168a8 8 0 0 1 0-16 44 44 0 1 0-16.34-84.87 8 8 0 1 1-5.94-14.85 60 60 0 0 1 55.53 105.64 95.83 95.83 0 0 1 47.22 37.71 8 8 0 0 1-2.33 11.07Z" />
    </svg>
  );
}

function IconExternalLink({
  className,
  ...props
}: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={cn("size-4", className)}
      viewBox="0 0 256 256"
      {...props}
    >
      <path d="M224 104a8 8 0 0 1-16 0V59.32l-66.33 66.34a8 8 0 0 1-11.32-11.32L196.68 48H152a8 8 0 0 1 0-16h64a8 8 0 0 1 8 8Zm-40 24a8 8 0 0 0-8 8v72H48V80h72a8 8 0 0 0 0-16H48a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-72a8 8 0 0 0-8-8Z" />
    </svg>
  );
}

function IconChevronUpDown({
  className,
  ...props
}: React.ComponentProps<"svg">) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className={cn("size-4", className)}
      viewBox="0 0 256 256"
      {...props}
    >
      <path d="M181.66 170.34a8 8 0 0 1 0 11.32l-48 48a8 8 0 0 1-11.32 0l-48-48a8 8 0 0 1 11.32-11.32L128 212.69l42.34-42.35a8 8 0 0 1 11.32 0Zm-96-84.68L128 43.31l42.34 42.35a8 8 0 0 0 11.32-11.32l-48-48a8 8 0 0 0-11.32 0l-48 48a8 8 0 0 0 11.32 11.32Z" />
    </svg>
  );
}

export {
  IconEdit,
  IconNextChat,
  IconOpenAI,
  IconVercel,
  IconGitHub,
  IconSeparator,
  IconArrowDown,
  IconArrowRight,
  IconUser,
  IconPlus,
  IconArrowElbow,
  IconSpinner,
  IconMessage,
  IconTrash,
  IconRefresh,
  IconStop,
  IconSidebar,
  IconMoon,
  IconSun,
  IconCopy,
  IconCheck,
  IconDownload,
  IconClose,
  IconShare,
  IconUsers,
  IconExternalLink,
  IconChevronUpDown,
  IconTung,
};
